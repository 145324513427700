<template>
	<div>
		<imgbg
			:full="false"
			class="d-block d-lg-none"
			height="30vh"
			:srcs="['candidats-1.jpg','candidats-2.jpg','candidats-3.jpg','candidats-4.jpg','candidats-5.jpg','candidats-8.jpg','candidats-6.jpg','candidats-7.jpg']"
		/>

		<section class="text-justify">
			<h2 class="text-primary">{{ $t('candidats.s1-h2') }}</h2>
			<div class="mt-2">{{ $t('candidats.s1-div') }}</div>
		</section>

		<section>
			<h2 class="text-primary text-justify">{{ $t('candidats.s2-h2') }}</h2>
			<div class="mt-2 text-justify">{{ $t('candidats.s2-div') }}</div>

			<div class="mt-4">
				<form @submit.prevent="submitForm">
					<div class="mb-3">
						<label for="nom">{{ $t('candidats.form.nomprenom') }}</label>
						<div class="inpcontainer">
							<input
								id="nom"
								:placeholder="$t('candidats.form.nomprenom-placeholder')"
								class="input padding bshadow w-100"
								v-model="form.nom"
								@input="resetErrors">
						</div>
						<div class="error" v-if="getError('nom')" :class="getError('nom').class">{{ $t(getError('nom').ref) }}</div>
					</div>

					<div class="mb-3">
						<label for="poste">{{ $t('candidats.form.poste') }}</label>
						<div class="inpcontainer">
							<input
								id="poste"
								:placeholder="$t('candidats.form.poste-placeholder')"
								class="input padding bshadow w-100"
								v-model="form.postes"
								@input="resetErrors">
						</div>
						<div class="error" v-if="getError('postes')" :class="getError('postes').class">{{ $t(getError('postes').ref) }}</div>
					</div>

					<div class="mb-3">
						<label for="fichiers">{{ $t('candidats.form.fichier') }}</label>
						<div class="inpcontainer">
							<input
								id="fichiers"
								ref="fichiers"
								type="file"
								multiple="multiple"
								accept="*/*"
								class="input padding bshadow w-100"
								@change="onChange"
								@blur="gtag('blur_mail_tel','contact', form.fichiers)">
						</div>
						<div class="error" v-if="getError('fichiers')" :class="getError('fichiers').class">{{ $t(getError('fichiers').ref) }}</div>
					</div>

					<div class="mb-3">
						<label for="message">{{ $t('candidats.form.message') }}</label>
						<div class="inpcontainer">
							<textarea
								class="input padding bshadow w-100 m-0"
								id="message"
								:placeholder="$t('candidats.form.message-placeholder')"
								rows="3"
								v-model="form.message"
								@input="auto_grow" />
						</div>
					</div>

					<div class="mt-3 text-center" v-if="getError()" :class="getError().class">{{ $t(getError().ref) }}</div>

					<div class="text-center">
						<btn-loading :handle="submitForm" color="primary btn-lg bshadow mt-3">{{ $t('candidats.form.send') }}</btn-loading>

						<div class="text-muted small mt-2">
							<span class="d-block d-sm-inline-block">{{ $t('candidats.ou-email') }} :</span>
							&nbsp;
							<a :href="'mailto:'+social.mail" @click="gtag('click', 'mail', 'contact')" class="text-muted btn-link">{{ social.mail }}</a>
						</div>
					</div>
				</form>
			</div>
		</section>
	</div>
</template>
<style lang="scss">
	@import '../../src/assets/less/variables.scss';

	label,
	div.error{
		display: inline-block;
		font-size: 80%;
		margin-left: 15px;
		line-height: 1em;
		margin-bottom: 0;
	}

	textarea,
	input{
		outline: none;
	}
	.padding{
		padding: 15px;
	}
	.inpcontainer{
		border-radius:5px;
		box-shadow: 0 1px 50px 6px rgba(137, 143, 156, 0.15);
	}
	.input{
		background: white!important;
		border:0;
		margin: 0;
		border-radius:5px;
		color: black;
	}
	textarea{
		width: 100%;
		padding: 15px;
		margin: 0;
		background: white!important;
		box-shadow: 0 1px 50px 6px rgba(137, 143, 156, 0.15);
		border: none;
		resize: none;
		overflow: hidden;
	}
</style>
<script>
	import BtnLoading from "../components/btn-loading";
	const axios = require('axios');

	export default{
		metaInfo () {
			return {
				title: this.$t("candidats.meta.title"),
				meta: [
					{name: 'description', content: this.$t("candidats.meta.description"), id: 'desc'},
				]
			}
		},
		components:{
			BtnLoading
		},
		data: function(){
			return {
				form:{
					nom:'',
					postes:'',
					fichiers:[],
					message:'',
				},
				callback:[],
			}
		},
		methods: {
			auto_grow: function(element) {
				this.resetErrors();
				let height = element.target.scrollHeight;
				if(!height || height<80) height = 80;
				element.target.style.height = height+"px";
			},
			resetErrors(){
				this.callback = [];
			},
			getError(field=null){
				let errors = this.callback.filter(c => c.input ? c.input===field : field===null);
				return errors[errors.length-1];
			},
			onChange(event) {
				var files = event.target.files || event.dataTransfer.files;
				if (!files.length) return;

				for (let i=0; i < files.length; i++) {
					var file = files[i];
					let size = file.size < 10*1024*1024;
					if(!size) {
						this.callback.push({input:'fichiers', ref:'candidats.form.erreurs.fichier-size', class:'text-danger', variable:size});
						this.$refs.fichiers.value = null;
					}else{
						this.form.fichiers.push(file);
					}
				}
			},
			submitForm:function(){
				this.gtag('submit','candidats');

				// Reset error
				this.resetErrors();

				// Check from
				if(!this.form.nom) this.callback.push({input:'nom', ref:'candidats.form.erreurs.nom-empty', class:'text-danger'});
				if(this.form.postes==='') this.callback.push({input:'postes', ref:'candidats.form.erreurs.postes-empty', class:'text-danger'});
				if(this.form.fichiers.length===0) this.callback.push({input:'fichiers', ref:'candidats.form.erreurs.fichiers-empty', class:'text-danger'});
				if(this.callback.length>0) return;

				// Form Data
				var data = new FormData();
				data.append('nom', this.form.nom);
				data.append('postes', this.form.postes);
				data.append('message', this.form.message);
				for (var pair of this.form.fichiers) {
					data.append('file[]', pair);
				}

				// Send
				return axios({
					method: 'post',
					url: process.env.VUE_APP_API+'api.php',
					data: data,
					headers: { 'content-type': 'application/json' },
				})
					.then(function (response) {
						if(response.data){
							this.callback.push({ref:'candidats.form.erreurs.'+response.data.ref, class:'text-success'});
							this.resetForm();
						}
					}.bind(this))
					.catch(function (error) {
						this.callback.push({ref:'candidats.form.erreurs.'+error.response.data.ref, input:error.response.data.input, class:'text-danger'});
					}.bind(this));
			},
			resetForm(){
				this.form = {
					nom:'',
					postes:'',
					fichiers:[],
					message:'',
				};

				this.$refs.fichiers.value = null;
			}
		},
	}
</script>
