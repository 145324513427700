<template>
	<component
		:is="to ? 'router-link':(href ? 'a':'div')"
		:to="to"
		:class="customClass"
		tabindex="0"
		:target="target"
		:href="href"
		:exact="exact"
		@keypress.enter="onClick"
		@click="onClick">
		<i v-if="fa" :class="fa"></i>
		<span :class="fa ? 'ml-2':''" v-if="$slots.default"><slot /></span>
	</component>
</template>
<script>
	export default{
		props:{
			to:{default:null},
			fa:{default:null},
			href:{default:null},
			target:{default:null},
			exact:{default:null},
			type:{default:'btn btn-secondary'},
			delete:{default:false},
			dark:{default:false},
			confirm:{default:"Supprimer ?"},
		},
		computed:{
			customClass(){
				if(this.delete!==false) return 'btn btn-dark';
				if(this.dark!==false) return 'btn btn-dark';
				return this.type;
			}
		},
		methods:{
			onClick(event){
				if(this.delete!==false){
					if(confirm(this.confirm)){
						this.$emit('click', event);
					}
				}else{
					this.$emit('click', event);
				}
			}
		}
	}
</script>
