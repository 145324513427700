<template>
	<div>
		<section class="text-justify">
			<h1>{{ $t('accueil.h1') }}</h1>
		</section>

		<imgbg :full="false" class="d-block d-lg-none d-print-none" height="40vh" :srcs="['accueil.jpg']" />

		<section class="text-justify">
			<h2 class="text-primary">{{ $t('accueil.s1-h2') }}</h2>
			<div class="mt-2"><div v-html="$t('accueil.s1-div')"></div></div>
		</section>

		<section class="text-justify">
			<h2 class="text-primary">{{ $t('accueil.s2-h2') }}</h2>
			<ul class="list-unstyled">
				<li class="mt-2">
					<span class="text-primary">{{ $t('accueil.s2-li1-title') }} :</span>
					{{ $t('accueil.s2-li1') }}
				</li>
				<li class="mt-2">
					<span class="text-primary">{{ $t('accueil.s2-li2-title') }} :</span>
					{{ $t('accueil.s2-li2') }}
				</li>
			</ul>
		</section>
	</div>
</template>
<script>
	export default{
		metaInfo () {
			return {
				title: this.$t("accueil.meta.title"),
				meta: [
					{name: 'description', content: this.$t("accueil.meta.description"), id: 'desc'},
				]
			}
		}
	}
</script>
