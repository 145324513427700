<template>
<!--	<div>-->
<!--		<btn @click="index=index+1">{{index}}</btn>-->
	<svg :key="index" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 400" width="1000px" class="logo" :class="{anim:anim}">
		<title>Nicole Marciano - Fashion Consulting - Logo</title>

		<defs>
			<clipPath id="clip-f-f">
				<path class="F-F" d="M520.81,97.44c-9.79-1-50.32-10-90.51-19.13a159.41,159.41,0,0,1,18.5-13.39c13.08-8,17-5,15.86-9.67-.94-3.93-22.92,3.3-46.37,20.34C374,65.58,238.82,38.29,146,58.41c-74.27,16.09-116.9,55-129.35,93.24S14,214.24,27.34,225.54l2.14-1.31C-1.31,266.88-13,315.42,19.1,335.1c51.29,31.46,130.45-6.23,152.25-18.34C214.15,293,255.58,254,293.22,215.21c16.77-.31,26.49-.07,56,2.2,4.79.37,4.5-6.38,0-6.59-38.29-1.81-38.14-2-50.17-1.7,22.78-23.7,44.09-47,63.39-66.42,26.87-27,44.63-44.06,57.42-55.45C521.3,106.36,536.05,103.67,536.22,98.1,536.32,95.12,530.26,98.42,520.81,97.44ZM29,332.25c-51.45-26.95-1.6-91.13,33.41-123C91.3,183,190,121.53,226.84,137c28.53,12-6.31,58.87-30.59,85.1-21.81,9.32-36.59,24.27-34.8,28.94,1,2.53,4.21,2.05,8.65-.44a26.3,26.3,0,0,0,4.68-3.05,96.61,96.61,0,0,0,7.57-6.19c2.94-2.51,6.35-5.52,10-8.94,16.65-12.31,40.35-15.32,73.28-16.51q-8,7.69-15.87,15.42C173.27,306.09,81,359.51,29,332.25ZM272.07,209.74c-26.31.95-50.38,4-65.41,8.54,19.89-21.08,40.14-49.25,35.69-72.74C237.06,117.66,149.56,125.7,82,172.29a221.18,221.18,0,0,0-50.23,48.83c-4.6-3.52-19.43-18.11-11.9-55.56C28.13,124.39,79,83.81,149.48,72.32c70.84-11.55,132.48-8.39,257,12.38l.43.08C391.41,98.71,331.9,152.28,272.07,209.74Z"/>
			</clipPath>
			<clipPath id="clip-f-as">
				<path class="F-A" d="M439.34,204c-5.17,1.45-14.42,7.88-25,16.64,13.37-22.23.62-29.31-31.63-18-27.61,9.72-55,29.37-78.17,56-23.92,27.58-33.16,58.65-10.79,59.76,14,.7,34-11.46,56.69-30.84-9.12,15.8-5.68,22.73-2.08,27.33,5.16,6.58,18.95,3.39,26.43-1.1,12.92-7.76,8-2.87,25.76-18.46,2.1-1.84,19.75-21.91,17.4-20-27.12,22.11-53.12,40.46-58.8,35.37-7-6.31,38.07-50.88,43.6-56.47,9.6-9.69,44.1-43,51.09-49.43C459.69,199.55,449.86,201.05,439.34,204ZM297.53,312.33C286,307.08,314.8,273,355,239.38c28-23.45,48.06-37.79,56.81-33.62,6.31,3-10,23.34-37.43,50.1C338,291.37,304.46,315.5,297.53,312.33Z"/>
<!--				</clipPath>-->
<!--				<clipPath id="clip-f-s">-->
				<path class="F-S" d="M481,277c7-13.33,9.79-39.67,15-54.28a38.27,38.27,0,0,1,4.32-8.9c16.68-13,53.31-35.31,47.42-43.83-7.79-11.29-34.81,17.93-51.74,36.91-12.24,13.72-34.48,33.74-51.13,47.71-6.61-.19-18.59,12-35.85,25.46-4.73,3.67-8.36,9.71-10.61,13.85a16.45,16.45,0,0,0-1.5,2.85,12.13,12.13,0,0,0-1.55,3.74c-3.11,10.15,2.44,20.94,26.21,19.11,31.16-2.39,48.92-15.49,69.36-28.56,14.57-9.32,15.86-4,15.86-4l29.72-28.56s-15.77.83-26.42,10.11c-21.51,18.72-45.9,31.92-45.36,30.54C465.81,296.21,474.18,289.83,481,277Zm-67.82,33.17c-17.91,1.46-16.11-10.34-2.37-21.53,6.92-4.16,17.12-10.61,24.17-16.48,8.59-7.14,13.49-11.42,15-14,12.62-11,26.06-23.3,38.09-34.47-2,7.39-7.28,23.14-19.41,41.63C451.9,290.79,430.89,308.69,413.13,310.13Z"/>
			</clipPath>
			<clipPath id="clip-f-h">
				<path class="F-H" d="M877.09,1.86C797,12,731.61,63.81,696.53,90c-66.74,49.77-158.13,140.75-177.7,171-3.93,6.08-10,11.36-13.08,16-17.42,25.9-19.7,30.73-17.64,37.75,3,10.12,5.9,8.27,9.24,2.44,7.86-13.67,28.17-39.4,51.2-59.94,26.37-23.52,62.56-41.68,76.34-47.79,14.66-6.5,21.22-3.72,13.65,4.46-9.33,10.11-52.83,43.85-70.86,69.66-13.34,19.08-10,39.45,8.14,37,24.55-3.34,55.73-30.24,82.61-53.58,2.49-2.16,17.9-26.66,14.76-24.32s-27.12,26.13-37,34.46-42.11,34.78-56.81,36.19c-22.85,2.19,25.49-39.79,48-61.75,14.53-14.14,33.44-32.32,28.34-45-4.94-12.3-22.92-8.15-43.31,1.24-20.8,9.58-45.08,25.95-42.72,23.08,19.23-23.26,80.06-82.07,150.18-139.31C753.12,64.36,858.31-18.42,947.52,14.83c59.28,22.09,42.62,77.87,28.22,90.3-5.63,4.86-.72,10,7.71,1.54C1021.31,68.64,997.6-13.36,877.09,1.86Z"/>
<!--				<path class="F-H" d="M877.09,1.86C797,12,731.61,63.81,696.53,90c-66.74,49.77-158.13,140.75-177.7,171-3.93,6.08-10,11.36-13.08,16-17.42,25.9-19.7,30.73-17.64,37.75,3,10.12,5.9,8.27,9.24,2.44,7.86-13.67,28.17-39.4,51.2-59.94,26.37-23.52,62.56-41.68,76.34-47.79,14.66-6.5,21.22-3.72,13.65,4.46-9.33,10.11-52.83,43.85-70.86,69.66-13.34,19.08-10,39.45,8.14,37,24.55-3.34,55.73-30.24,82.61-53.58,2.49-2.16,17.9-26.66,14.76-24.32s-27.12,26.13-37,34.46-42.11,34.78-56.81,36.19c-22.85,2.19,25.49-39.79,48-61.75,14.53-14.14,33.44-32.32,28.34-45-4.94-12.3-22.92-8.15-43.31,1.24-20.8,9.58-45.08,25.95-42.72,23.08,19.23-23.26,80.06-82.07,150.18-139.31C753.12,64.36,858.31-18.42,947.52,14.83c59.28,22.09,42.62,77.87,28.22,90.3-5.63,4.86-.72,10,7.71,1.54C1021.31,68.64,997.6-13.36,877.09,1.86Z"/>-->
<!--				<path class="F-H" d="M877.09,1.86C797,12,731.61,63.81,696.53,90c-66.74,49.77-158.13,140.75-177.7,171-3.93,6.08-10,11.36-13.08,16-17.42,25.9-19.7,30.73-17.64,37.75,3,10.12,5.9,8.27,9.24,2.44,7.86-13.67,28.17-39.4,51.2-59.94,26.37-23.52,62.56-41.68,76.34-47.79,14.66-6.5,21.22-3.72,13.65,4.46-9.33,10.11-52.83,43.85-70.86,69.66-13.34,19.08-10,39.45,8.14,37,24.55-3.34,55.73-30.24,82.61-53.58,2.49-2.16,17.9-26.66,14.76-24.32s-27.12,26.13-37,34.46-42.11,34.78-56.81,36.19c-22.85,2.19,25.49-39.79,48-61.75,14.53-14.14,33.44-32.32,28.34-45-4.94-12.3-22.92-8.15-43.31,1.24-20.8,9.58-45.08,25.95-42.72,23.08,19.23-23.26,80.06-82.07,150.18-139.31C753.12,64.36,858.31-18.42,947.52,14.83c59.28,22.09,42.62,77.87,28.22,90.3-5.63,4.86-.72,10,7.71,1.54C1021.31,68.64,997.6-13.36,877.09,1.86Z"/>-->
				<path class="F-I" d="M769.5,160.18c-4.22-4-13.6-6.05-17.7,1.42-3.8,6.91-2.42,18.87,11.23,17.14C776,177.1,772.66,163.17,769.5,160.18Zm-48.09,44c-12.81,3.59-48.58,36.1-69.14,60.42-29,34.3-25.42,39.72-23.56,49.44,1.56,8.21,19.91,6.42,28,3.07,15.53-6.45,28.13-15.07,45.89-30.66,2.1-1.83,10.42-15.86,8.06-13.94-27.12,22.12-62.71,48.06-68.26,38.89-4.9-8.09,38.29-51.32,43.82-56.91,9.59-9.69,45-43.68,52-50.09C744,199.11,731.94,201.27,721.41,204.23Z"/>
			</clipPath>
			<clipPath id="clip-f-io">
				<path class="F-I" d="M769.5,160.18c-4.22-4-13.6-6.05-17.7,1.42-3.8,6.91-2.42,18.87,11.23,17.14C776,177.1,772.66,163.17,769.5,160.18Zm-48.09,44c-12.81,3.59-48.58,36.1-69.14,60.42-29,34.3-25.42,39.72-23.56,49.44,1.56,8.21,19.91,6.42,28,3.07,15.53-6.45,28.13-15.07,45.89-30.66,2.1-1.83,10.42-15.86,8.06-13.94-27.12,22.12-62.71,48.06-68.26,38.89-4.9-8.09,38.29-51.32,43.82-56.91,9.59-9.69,45-43.68,52-50.09C744,199.11,731.94,201.27,721.41,204.23Z"/>
<!--			</clipPath>-->
<!--			<clipPath id="clip-f-o">-->
				<path class="F-O" d="M794.52,202.91c-35.5,12.34-64.12,37.18-84.12,65.92-20.49,29.42-12.23,52.37,5.28,51.41,22.23-1.21,54.1-23.52,93.37-67.23C845,213,836.33,188.37,794.52,202.91Zm-5.29,55.37c-36.42,35.5-66.87,61.51-76.19,55.15-10.69-7.29,15.93-37.12,54.17-73,24.71-23.15,46.95-38.73,55.49-34.28C827.93,208.93,816.69,231.52,789.23,258.28Z"/>
			</clipPath>
			<clipPath id="clip-f-n">
				<path class="F-N" d="M956.36,262.67c-3.14,2.35-9,6.61-18.94,14.94s-42.11,35.29-56.81,36.7c-22.84,2.19,25.24-39.78,47.79-61.74,14.52-14.15,34-32.33,28.84-45-4.93-12.3-21.2-7.64-39.41,1.54-20.45,10.3-55.52,36.65-53.73,33.4,3-5.49,30.5-31.06,40.73-37.79,6.61-4.34-4.31-2.65-17.39-.22s-46.16,35.66-66.72,60c-29,34.3-31.29,42.24-30.17,52.08,1,8.29,5.66,6.33,9.25.65,4.17-6.59,29.52-35.37,50.87-54.71,22.4-20.29,64.52-44.76,78-51.63s18.35-3.57,10.79,4.61c-9.34,10.11-53.46,45.47-70.47,68.12-14,18.61-10,40.47,8.15,38,24.55-3.34,55.47-30.5,82.35-53.83C961.94,265.56,959.51,260.33,956.36,262.67Z"/>
			</clipPath>
		</defs>
		<g class="fashion-path" :class="colorf">
			<g clip-path="url(#clip-f-f)" stroke-width="40">
<!--				<path class="f" stroke-miterlimit="10" d="M26.89,221.12C16.17,210.51,9.1,177.47,17.76,158s24.31-47,58.6-67.83C109,70.28,184.87,51.19,259.9,59c86.62,9,145.57,21.44,207.85,32.16s66.17,10.25,75,3.26-27.42-56.2-69-44.08S395.51,97.72,305.57,189.52,115.44,353.5,57.18,343.24,2.66,304.86,6.85,279.4C12,248.17,87.51,159.76,173.22,137.73c83.42-21.44,74.47,20.19,47.6,58.62s-61.25,60.27-55.19,50.29c7.28-12,27.65-27.39,86.15-32.48s100.86-.66,100.86-.66"/>-->
<!--				<path class="f" stroke-miterlimit="10" d="M26.89,221.12C16.17,210.51,9.1,177.47,17.76,158s24.31-47,58.6-67.83C109,70.28,184.87,51.19,259.9,59c86.62,9,145.57,21.44,207.85,32.16s66.17,10.25,75,3.26-27.42-56.2-69-44.08S395.51,97.72,305.57,189.52,115.44,353.5,57.18,343.24,2.66,304.86,6.85,279.4C12,248.17,87.51,159.76,173.22,137.73c83.42-21.44,74.47,20.19,47.6,58.62s-61.25,60.27-55.19,50.29c7.28-12,27.65-27.39,86.15-32.48s100.86-.66,100.86-.66"/>-->
				<path class="f" stroke-miterlimit="10" data-name="f" d="M26.89,221.12C16.17,210.51,9.1,177.47,17.76,158s24.31-47,58.6-67.83C109,70.28,184.87,51.19,259.9,59c86.62,9,145.57,21.44,207.85,32.16s66.17,10.25,75,3.26-27.42-56.2-69-44.08S395.51,97.72,305.57,189.52,115.44,353.5,57.18,343.24,2.66,304.86,6.85,279.4C12,248.17,87.51,159.76,173.22,137.73c83.42-21.44,74.47,20.19,47.6,58.62s-61.25,60.27-55.19,50.29c7.28-12,27.65-27.39,86.15-32.48s100.86-.66,100.86-.66"/>

			</g>
			<g clip-path="url(#clip-f-as)" stroke-width="40">
<!--				<path class="ash" stroke-miterlimit="10" d="M416,211.8c2.1-10.6-14.6-21.9-64.3,14.4s-84,89.8-53.6,88.1c30.4-1.7,131.9-105.6,145.9-108.9 c14.1-3.4-73.3,63.5-86.8,81.9c-14,19.1-6.1,41.9,31.7,13.5s107.5-92.2,114.4-95.2c50.5-21.6,57-64.6-0.1-6.9 c-24.6,24.8-9.5,56.1-45.3,93.7c-12.5,13.1-63.5,38.2-60.7,5.9c-2.8,14.3,12.1,26.7,55.2,9c49.9-20.5,121.4-101.9,148.6-126.2 c25.6-23,105.7-97.5,192.9-148c97.9-56.6,253-27.3,185.9,76.6"/>-->

<!--				<path class="as" stroke-miterlimit="10" d="M416,211.83c2.08-10.64-14.62-21.91-64.31,14.44s-84,89.83-53.59,88.12S430,208.82,444.05,205.44,370.76,269,357.3,287.32c-14,19.11-6.06,41.94,31.69,13.52s107.51-92.25,114.44-95.22C554,184,560.48,141,503.31,198.7c-24.57,24.79-9.47,56.07-45.35,93.75-12.52,13.14-63.46,38.21-60.69,5.86-2.77,14.3,18,41.16,103.16-17.57"/>-->
				<path class="as" stroke-miterlimit="10" d="M412.26,211.65c7.66-14-19.57-18.05-69.26,18.3s-73.27,97.41-40.34,84.44c27.19-10.71,111.23-104,136.68-110.38,21.09-5.31-66.57,68.18-80,86.52-14,19.11-6.53,32.22,31.22,3.8s99.64-88.67,106.9-90.71c56.26-15.81,65.05-59.63,7.88-1.92-24.57,24.79-11.47,47.07-47.35,84.75-12.52,13.14-63.46,44.21-60.69,11.86C394.5,312.61,418.6,346,518.68,267"/>

			</g>
			<g clip-path="url(#clip-f-h)" stroke-width="40">
<!--				<path class="hi" stroke-miterlimit="10" d="M26.9,221.1c-10.7-10.6-17.8-43.7-9.1-63.2s24.3-47,58.6-67.8C109,70.3,184.9,51.2,259.9,59 c86.6,9,145.6,21.4,207.8,32.2s66.2,10.3,75,3.3c8.9-7-27.4-56.2-69-44.1s-78.3,47.4-168.2,139.2s-190.1,164-248.4,153.7 S2.7,304.9,6.8,279.4c5.1-31.2,80.7-119.6,166.4-141.7c83.4-21.4,74.5,20.2,47.6,58.6s-61.3,60.3-55.2,50.3 c7.3-12,27.6-27.4,86.1-32.5s100.9-0.7,100.9-0.7"/>-->
<!--				</g>-->
<!--				<g clip-path="url(#clip-f-i)" stroke-width="40">-->
<!--				<path class="hi" stroke-miterlimit="10" d="M491.5,313.9c4.3-6.9,29.9-48.2,68.5-72.2c28.9-18,81.4-53.1,90.4-31.2 c6.4,15.5-47.8,46.4-68.8,68.5c-23.1,24.2-19,49.4,10.6,33.7c39.3-20.9,108.8-94.9,129.2-108.4"/>-->

<!--				<path class="h" stroke-miterlimit="10" d="M979.83,109.66C1047,5.85,891.84-23.51,794,33.1c-87.19,50.44-117.11,82.31-192.93,148S491.51,313.88,491.51,313.88c4.34-6.89,29.87-48.19,68.51-72.23,28.89-18,81.4-53.13,90.41-31.22,6.35,15.46-47.81,46.4-68.84,68.5-23.06,24.24-19,49.4,10.58,33.68,39.28-20.87,108.8-94.87,129.24-108.38"/>-->
				<path class="h" stroke-miterlimit="10" d="M979.83,109.66C1047,5.85,891.84-23.51,794,33.1c-87.19,50.44-117.11,79.31-192.93,145S491.51,311.88,491.51,311.88c4.34-6.89,29.87-46.19,68.51-70.23,28.89-18,81.4-53.13,90.41-31.22,6.35,15.46-47.81,46.4-68.84,68.5-23.06,24.24-13.58,50.62,13.81,31.33,35.32-24.88,111.16-111.54,131.59-125"/>

			</g>
			<g clip-path="url(#clip-f-io)" stroke-width="40">
<!--				<path class="o" stroke-miterlimit="10" d="M729.6,205.4c-22.2,18.6-73.9,65.8-88.2,85.3c-13.7,18.6-6.6,41,29.2,15.5 c31.5-22.5,40.7-20.7,37.2-15.6c-3.3,4.7-9.9,49.3,39,12.4c50.5-38.1,94.8-93.7,76.2-99.8c-18.6-6.1-81.6,28.7-115.2,83.7"/>-->
<!--				<path class="io" stroke-miterlimit="10" d="M767.31,161c-17.93,21.91-111.52,110.29-125.89,129.76-13.74,18.61-6.61,41,29.17,15.49,31.55-22.52,40.74-20.75,37.16-15.63-3.27,4.66-10,49.34,39,12.44,50.47-38,94.76-93.74,76.2-99.83s-81.65,28.67-115.2,83.66"/>-->
				<path class="io" stroke-miterlimit="10" d="M767.31,161c-17.93,21.91-111.52,110.29-125.89,129.76-13.74,18.61-6.61,41,29.17,15.49,31.55-22.52,40.74-20.75,37.16-15.63-3.27,4.66-10,49.34,39,12.44,50.47-38,94.76-93.74,76.2-99.83s-81.65,28.67-115.2,83.66"/>
			</g>
			<g clip-path="url(#clip-f-n)" stroke-width="40">
<!--				<path class="n" stroke-miterlimit="10" d="M891.8,205.4c-54.5,44.6-94.9,96.9-97,108.2c-2.3,11.9,23.4-41.8,67.7-67.4 c44.3-25.6,84.3-57.7,90.9-37.3c5,15.2-43,38.8-65.4,64c-22.4,25.2-26.9,44-9.7,44.8c17.1,0.8,51.7-26.8,76.9-50.7"/>-->
<!--				<path class="n" stroke-miterlimit="10" d="M891.84,205.44c-54.54,44.6-94.88,96.87-97,108.17-2.26,11.92,23.44-41.8,67.71-67.44s84.28-57.65,90.91-37.3c5,15.25-43,38.84-65.41,64s-26.86,44-9.72,44.82S930,290.88,955.2,267"/>-->
				<path class="n" stroke-miterlimit="10" d="M899.42,196.31c-65.71,43-102.46,104-104.6,115.3-2.26,11.92,25.44-39.8,69.71-65.44s82.28-57.65,88.91-37.3c5,15.25-43,38.84-65.41,64s-26.86,44-9.72,44.82S930,290.88,955.2,267"/>
			</g>
		</g>

		<defs>
			<radialGradient id="star">
				<stop id="SvgjsStop1930" stop-opacity="1" stop-color="#ffffff" offset="0"></stop>
				<stop id="SvgjsStop1931" stop-opacity="0" stop-color="#ffffff" offset="1"></stop>
			</radialGradient>
		</defs>
		<g class="star">
			<ellipse rx="100" ry="100" cx="0" cy="0" fill="url(#star)" opacity="0.9"></ellipse>
			<polyline points="-180,0 -10,-4 -127,-127 -4,-10 0,-180 4,-10 127,-127 10,-4 180,0 10,4 127,127 4,10 0,180 -4,10 -127,127 -10,4 -180,0"
								fill="url(#star)"></polyline>
		</g>

		<g class="consulting" :class="colorc">
			<path class="c" style="--d:2.0s" d="M219.8,346c1.33.21,2.67.35,4,.64,5.31,1.2,8.59,4.72,9.21,10.13a71.39,71.39,0,0,1,.28,7.88,2.33,2.33,0,0,1-2.42,2.58c-2.71.1-5.43.1-8.14,0-1.57-.06-2.39-1.09-2.48-2.69-.06-1.09,0-2.2-.21-3.28a4.2,4.2,0,0,0-4.1-4,22.64,22.64,0,0,0-2.86-.16H191.6c-.8,0-1.61,0-2.41.11-2.81.24-4.44,1.83-4.59,4.65-.1,2.05,0,4.1,0,6.15q0,7.47,0,14.93a10.22,10.22,0,0,0,.19,2.07,4.31,4.31,0,0,0,4.2,3.8,23.06,23.06,0,0,0,2.63.15c7.19,0,14.38,0,21.57,0a19.33,19.33,0,0,0,3.39-.3A4,4,0,0,0,220,385a30.83,30.83,0,0,0,.23-3.72c.07-2.12,1-3,3.14-3q3.51,0,7,0c1.85,0,2.9.88,2.87,2.69a62.25,62.25,0,0,1-.5,9.28c-.89,5.33-4.47,8.52-9.83,9.38L220,400H184.13c-.94-.14-1.87-.26-2.8-.43-5.22-.95-8.68-3.81-9.86-9.12-.19-.84-.37-1.69-.56-2.53V358a6.09,6.09,0,0,0,.22-.81c.8-6,3.94-9.48,9.77-10.74,1.2-.26,2.44-.36,3.67-.53Z"/>
			<path class="o" style="--d:2.1s" d="M265.16,400c-1.08-.16-2.17-.28-3.24-.5-5.66-1.15-8.9-4.55-9.74-10.26a35,35,0,0,1-.37-4.8c0-7.64-.06-15.28,0-22.93a30.24,30.24,0,0,1,.63-6.08c1.14-5.17,4.66-8.26,10-9.07l2.91-.41h37.21c1.05.14,2.1.27,3.14.43,5.33.83,8.69,3.84,9.87,9.1a27.36,27.36,0,0,1,.61,5.64c.08,5.86.05,11.72,0,17.58,0,3.18.09,6.38-.23,9.54-.62,6.34-3.71,9.91-9.64,11.21-1.09.24-2.22.37-3.33.55Zm18.89-11.28h0c4.36,0,8.73.05,13.09,0,3.76-.06,5.46-1.74,5.49-5.48q.08-10.28,0-20.54c0-3.72-1.87-5.42-5.65-5.42H271.22c-3.9,0-5.77,1.71-5.81,5.54q-.11,10.15,0,20.31c0,3.83,1.8,5.54,5.65,5.6C275.39,388.77,279.72,388.72,284.05,388.72Z"/>
			<path class="n1" style="--d:2.2s" d="M388.69,346h7.93c2.26.79,2.65,1.33,2.65,3.75v46.58c0,2.33-.38,2.89-2.43,3.72h-7a7.92,7.92,0,0,1-3.93-2.44c-.25-.26-.54-.49-.81-.74L353,367.61l-4.31-3.88v32.63c0,2.38-.33,2.84-2.48,3.64H338c-2-.82-2.42-1.4-2.42-3.72V349.71c0-2.42.39-3,2.64-3.76h7.27a7.82,7.82,0,0,1,3.84,2.17q17.16,15.69,34.35,31.34c.77.7,1.55,1.38,2.5,2.22v-1.34q0-15.31,0-30.64C386.22,347.21,386.51,346.77,388.69,346Z"/>
			<path class="s" style="--d:2.3s" d="M429.87,346h40.07c.43.06.86.16,1.29.19,4.64.32,7.77,2.78,8.73,7.13a27.12,27.12,0,0,1,.5,5.33c.05,2.2-.84,3-3.07,3.08q-3.79,0-7.59,0c-1.9,0-2.44-.6-2.52-2.52a2.49,2.49,0,0,0-1.94-2.6,7.87,7.87,0,0,0-2.16-.29q-13.05,0-26.09,0a7.48,7.48,0,0,0-1.42.1,2.78,2.78,0,0,0-2.5,2.53,19.55,19.55,0,0,0-.11,3.82c.18,1.92,1.06,2.76,3,3,1.63.22,3.27.31,4.92.42,5.66.38,11.33.73,17,1.13,4.74.33,9.51.57,14.23,1.11,5.79.65,8.9,3.71,9.13,9,.19,4.46.17,8.93,0,13.39-.19,4.7-2.76,7.69-7.1,8.73-.91.21-1.85.34-2.78.51H428.77c-.36-.06-.71-.16-1.06-.19-5-.39-8.22-3.36-8.85-8.31a42.27,42.27,0,0,1-.26-5c0-2.26.85-3.1,3.14-3.12s4.77,0,7.15,0c2.06,0,2.91.72,2.87,2.73-.05,2.46,1.28,3.62,3.76,3.62H463.8a8,8,0,0,0,1,0,2.91,2.91,0,0,0,3-2.79,28.83,28.83,0,0,0,.14-4.16c-.07-2.76-1.1-3.8-3.88-4l-7.56-.51c-6-.4-12-.77-18-1.21-3.65-.26-7.33-.42-10.93-1-4.83-.74-7.53-3.34-7.92-8.17a85.69,85.69,0,0,1,.11-13.78c.32-4.11,2.92-6.65,7-7.59C427.77,346.22,428.82,346.12,429.87,346Z"/>
			<path class="u" style="--d:2.4s" d="M551.64,346H560c2.14.82,2.58,1.47,2.58,3.85,0,11.54,0,23.07,0,34.61a35.32,35.32,0,0,1-.38,4.92c-.83,5.54-3.93,8.85-9.42,10.08-1.17.27-2.37.4-3.56.59H513.76c-.93-.14-1.87-.26-2.8-.42-5.13-.92-8.67-3.65-9.82-8.88a28.6,28.6,0,0,1-.67-6c-.06-11.68,0-23.35,0-35,0-2.36.4-2.94,2.52-3.75h8.37c2.31.8,2.65,1.29,2.65,3.85q0,16.24,0,32.5c0,4.63,1.67,6.26,6.32,6.27h21.9c5.23,0,6.77-1.52,6.77-6.71V349.69C549,347.26,549.37,346.72,551.64,346Z"/>
			<path class="l" style="--d:2.5s" d="M593,346c2.11.8,2.46,1.31,2.46,3.66q0,18.6,0,37.22v1.45h34.17c.4,0,.81,0,1.21,0,2,.11,2.87.88,3,2.9s0,3.88,0,5.82a2.89,2.89,0,0,1-2.51,3h-46.9c-2.09-.79-2.46-1.34-2.46-3.68q0-23.32,0-46.66c0-2.45.35-2.93,2.68-3.71Z"/>
			<path class="t" style="--d:2.6s" d="M690.36,346c2.22.78,2.62,1.36,2.62,3.78,0,1.53,0,3.07,0,4.61,0,2.34-.94,3.32-3.35,3.35-3.3.05-6.6,0-9.9,0H669.65v38.54c0,2.31-.4,2.91-2.41,3.75h-8.81c-1.92-.55-2.36-1.95-2.36-3.77,0-12.41,0-24.81,0-37.22v-1.3H636.78a11.55,11.55,0,0,1-1.86-.12,2.29,2.29,0,0,1-2.06-2.23c-.1-2.11-.07-4.24-.1-6.36a2.88,2.88,0,0,1,2.55-3Z"/>
			<path class="i" style="--d:2.7s" d="M721.19,346c2.32.75,2.66,1.23,2.66,3.7v46.67c0,2.39-.32,2.86-2.44,3.68H712.6c-2.08-.86-2.42-1.36-2.42-3.71v-46.6c0-2.45.38-3,2.64-3.74Z"/>
			<path class="n2" style="--d:2.8s" d="M747,346h7.27a8,8,0,0,1,3.93,2.24q18,16.5,36.14,33a5.74,5.74,0,0,0,.63.45v-1.25q0-15.37,0-30.75c0-2.36.33-2.84,2.46-3.65h7.93c2.3.8,2.65,1.29,2.66,3.73V396.3c0,2.33-.37,2.88-2.44,3.7h-7a14.59,14.59,0,0,1-3-1.66q-18.26-16.51-36.42-33.12c-.49-.45-1-.88-1.68-1.47v1.7c0,10.28,0,20.57,0,30.85,0,1.81-.48,3.12-2.3,3.7h-8.37c-2-.83-2.41-1.41-2.41-3.73V349.72C744.32,347.3,744.72,346.74,747,346Z"/>
			<path class="g" style="--d:2.9s" d="M841,346h37.21c1,.14,2,.27,3,.43,5.46.83,8.84,4,10,9.32.27,1.21.42,2.44.62,3.65v3.3c-.35.91-.79,1.79-1.93,1.8-3.37,0-6.74,0-10.11,0-1,0-1.49-.7-1.6-1.64-.06-.47,0-.95-.05-1.43a4.09,4.09,0,0,0-3.58-4.2,16.24,16.24,0,0,0-3-.24q-12,0-24.1,0a16.16,16.16,0,0,0-2.09.09c-2.66.35-4,1.65-4.39,4.26a10.08,10.08,0,0,0-.08,1.65q0,6.79,0,13.61c0,2.64,0,5.28,0,7.91s1.5,4.33,4,4.73a16.47,16.47,0,0,0,2.63.21q12.15,0,24.32,0a16.44,16.44,0,0,0,2.62-.22c1.81-.29,3.28-1.17,3.61-3s.4-4.09.59-6.26H863.41c-2.47,0-3.15-.69-3.15-3.12,0-1.2,0-2.41,0-3.62,0-2.47.72-3.21,3.18-3.21,8.22,0,16.44,0,24.66,0,1.84,0,3.21.5,3.74,2.41V386.6c-.07.42-.17.84-.2,1.27-.45,6.35-3.85,10.37-9.88,11.62-1.11.22-2.23.34-3.35.51H840.54c-.93-.14-1.87-.26-2.8-.43-5.22-.95-8.73-3.81-9.83-9.13a30.19,30.19,0,0,1-.6-5.76c-.06-7.8,0-15.59,0-23.38a36.54,36.54,0,0,1,.25-4.05c.69-5.94,3.92-9.51,9.75-10.76C838.51,346.23,839.75,346.12,841,346Z"/>
		</g>
	</svg>
<!--	</div>-->
</template>
<style lang="scss" scoped>
	@import '../../src/assets/less/variables.scss';

	.logo{
		max-width: 100%;

		.fashion-path {
			&.primary path{ stroke: $primary-color; fill:none; }
			&.black path{ stroke: black; fill:none; }
			&.white path{ stroke: white; fill:none; }
		}
		.consulting{
			&.primary path{ fill: $primary-color; stroke:none; }
			&.black path{ fill: black; stroke:none; }
			&.white path{ fill: white; stroke:none; }
		}

		&.anim{
			.fashion-path {
				.f {
					stroke-dasharray: 2600;
					stroke-dashoffset: 2600;
					stroke-width: 20;
					animation: anata-a-1 1s linear forwards;
				}
				.as {
					stroke-dasharray: 2600;
					stroke-dashoffset: 2600;
					stroke-width: 25;
					animation: anata-other-letters 1.5s linear forwards .9s;
				}
				.h {
					stroke-dasharray: 2600;
					stroke-dashoffset: 2600;
					stroke-width: 30;
					animation: anata-other-letters 1.5s linear forwards 1.5s;
				}
				.io {
					stroke-dasharray: 2600;
					stroke-dashoffset: 2600;
					stroke-width: 25;
					animation: anata-other-letters .8s linear forwards 2.5s;
				}
				.n {
					stroke-dasharray: 2600;
					stroke-dashoffset: 2600;
					stroke-width: 25;
					animation: anata-other-letters .8s linear forwards 3s;
				}
			}
			.consulting{
				.c,
				.o,
				.n1,
				.s,
				.u,
				.l,
				.t,
				.i,
				.n2,
				.g{
					opacity: 0;
					animation: fade 2s cubic-bezier(0, 0.15, 0.3, 1) forwards var(--d, 2s);
					transform-origin: center;
				}
			}

			.star{
				animation: anim-star 1s ease-in-out 1.8s both;
				polyline{
					animation: anim-star-scale 1s ease-in-out 1.8s both;
				}
				//transform: scale(0.5) translate(1520px, 335px);
				// ease-in-out forwards 1s;
				//transform-origin: center;
				//transform:scale(0.5) translate(1520 335);
			}
		}

	}

	@keyframes anim-star {
		0% {
			opacity: 0;
			//stroke-dashoffset: 2600;
			transform: scale(0.5) translate(1520px, 335px) rotate(0deg);
		}
		50% {
			opacity: 1;
			//transform: scale(0.25) translate(3040px, 670px);
		}
		100% {
			opacity: 0;
			transform: scale(0.5) translate(1520px, 335px) rotate(150deg);
		}
	}
	@keyframes anim-star-scale {
		0% {
			//opacity: 0;
			//stroke-dashoffset: 2600;
			transform: scale(0);
		}
		//50% {
		//	//opacity: 1;
		//	transform: scale(1);
		//}
		100% {
			//opacity: 0;
			transform: scale(2);
		}
	}

	@keyframes anata-a-1 {
		0% {
			stroke-width: 10;
			stroke-dashoffset: 2600;
		}
		//14% {
		//	stroke-width: 10;
		//}
		//15% {
		//	stroke-width: 10;
		//}
		100% {
			stroke-dashoffset: 0;
		}
	}
	@keyframes anata-other-letters {
		0% {
			stroke-dashoffset: 2600;
		}
		100% {
			stroke-dashoffset: 0;
		}
	}
	@keyframes fade {
		0% {
			transform: translateY(-20px);
			//transform: scale(.9);
			opacity: 0;
		}
		100% {
			//transform: scale(1);
			transform: translateY(0);
			opacity: 1;
		}
	}
</style>

<script>
	export default {
		name:'logo',
		props: {
			anim:{ default:true },
			colorf:{ default:'primary' },
			colorc:{ default:'black' }
		},
		data: function(){
			return {
				index:0,
			}
		},
	}
</script>
