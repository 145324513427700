<template>
	<nav v-on-click-outside="close">
<!--		<btn @click="all=!all">{{all}}</btn>-->
<!--		-->
		<div class="top" @click="open=!open" :key="$router.currentRoute.name">

			<div class="page ellipsis">
				<span class="d-none">{{ $router.currentRoute.name+'.meta.title' }}</span>
				{{ $t($router.currentRoute.name+'.meta.title') }}
			</div>
			<div class="flex-grow-1"></div>
<!--			<div class="title">{{ $t('header.menu') }}</div>-->
			<div class="menu"><i :class="open ? 'fa fa-times':'fa fa-bars'"></i></div>
		</div>

		<ul :class="{all:all, open:open}">

			<li class="flag">

				<a
					class="d-inline-block"
					:class="{'active':langue==='en'}"
					@click="playSound('mail'); close(); $store.commit('setLanguage', 'en')">
					<svg xmlns="http://www.w3.org/2000/svg" id="flag-icon-css-us" viewBox="0 0 640 480">
						<path fill="#012169" d="M0 0h640v480H0z"/>
						<path fill="#FFF" d="M75 0l244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z"/>
						<path fill="#C8102E" d="M424 281l216 159v40L369 281h55zm-184 20l6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z"/>
						<path fill="#FFF" d="M241 0v480h160V0H241zM0 160v160h640V160H0z"/>
						<path fill="#C8102E" d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z"/>
					</svg>
				</a>
				<a
					class="d-inline-block"
					:class="{'active':langue==='fr'}"
					@click="playSound('mail'); close(); $store.commit('setLanguage', 'fr')">
					<svg xmlns="http://www.w3.org/2000/svg" id="flag-icon-css-fr" viewBox="0 0 640 480">
						<g fill-rule="evenodd" stroke-width="1pt">
							<path fill="#fff" d="M0 0h640v480H0z"/>
							<path fill="#00267f" d="M0 0h213.3v480H0z"/>
							<path fill="#f31830" d="M426.7 0H640v480H426.7z"/>
						</g>
					</svg>
				</a>
			</li>

			<transition-group
				name="list"
				v-on:before-enter="beforeEnter"
				v-on:enter="enter"
				v-on:leave="leave">

				<template v-if="all">

<!--					<router-link18n-->
<!--						tag="li"-->
<!--						:key="'anim'"-->
<!--						v-bind:data-index="1"-->
<!--						:to="{name:'anim'}">-->
<!--						<a>Anim</a>-->
<!--					</router-link18n>-->

					<router-link18n
						tag="li"
						:key="'accueil'"
						v-bind:data-index="1"
						:to="{name:'accueil'}"
						@click="playSound('link'); close(); gtag('click', 'accueil', 'nav')"
						class="d-noneX d-md-blockX">
						<a>{{ $t('header.accueil') }}</a>
					</router-link18n>

					<router-link18n
						tag="li"
						:key="'clients'"
						v-bind:data-index="2"
						:to="{name:'clients'}"
						@click="playSound('link'); close()"
						class="d-noneX d-md-blockX">
						<a>{{ $t('header.espace-clients') }}</a>
					</router-link18n>

					<router-link18n
						tag="li"
						:key="'candidats'"
						v-bind:data-index="3"
						:to="{name:'candidats'}"
						@click="playSound('link'); close()"
						class="d-noneX d-md-blockX">
						<a>{{ $t('header.espace-candidats') }}</a>
					</router-link18n>

					<router-link18n
						tag="li"
						:key="'contact'"
						v-bind:data-index="4"
						:to="{name:'contact'}"
						@click="playSound('link'); close()">
						<a>{{ $t('header.contact') }}</a>
					</router-link18n>
				</template>
			</transition-group>

<!--			<li class="flex-grow-1 p-0"></li>-->

		</ul>
	</nav>
</template>
<style lang="scss">
@import '../../src/assets/less/variables.scss';

.list-item {
}
.list-enter-active, .list-leave-active {
	transition: all 1s;
}
.list-enter, .list-leave-to {
	opacity: 0;
	transform: translateY(-30px);
}


nav{
	text-align: right;
	position: sticky;
	top: 0;
	right: 0;
	z-index: 1;
	display: inline-block;
	margin: 0 calc(50% - 50px) 2vh 0;
	@include notebook(){
		margin: 0 calc(70% - 30px) 2vh 0;
	}
	.top{
		display: none;
	}
	@media only screen and (max-width: 990px) {
		margin: 0;
		.top{
			display: flex;
			padding: 0 5vw;
			background: $secondary-color;
			.page,
			.menu {
				padding: 15px 0;
			}
			.title {
				padding: 15px;
			}
		}

		ul.open {display: block;}
		ul:not(.open){
			display: none;
			background: red;
		}
		ul{
			background: whitesmoke!important;
			li{
				margin: 0!important;
			}

			span:not(:empty){
				display: block;
				padding-top: 2vh!important;
				padding-bottom: 2vh!important;
			}
		}
	}

	ul{
		margin: 0;
		min-width: 240px;
		max-height: 0;
		transition: all 0.5s;
		display: inline-block;
		padding: 0;
		text-align: center;
		background: rgba(255, 255, 255, 0.6);
		backdrop-filter: blur(50px);
		@supports not (backdrop-filter: blur(50px)) {
			background: white;
		}
		@include smartphones-xs(){
			display: block;
			text-align: center;
		}

		&.all,
		&:not(.all).open{
			max-height: 500px;
		}

		li{
			list-style: none;
			transition:all 0.2s ease-in-out;
			display: block;
			margin: 5px 0;
			position: relative;

			a{
				display: block;
				transition:all 0.2s ease-in-out;
				color: $primary-color;
				text-decoration: none;
				position: relative;
				text-shadow: none;
				letter-spacing: 0.05em;
				line-height: 1em;
				margin: 0;
				padding: 16px 2vw 2px;

				&::first-letter {
					font-family: 'Champignon';
					font-weight: bolder;
					font-size: 200%;
				}
			}

			&.flag{
				padding-top: 2vh;
				padding-bottom: 2vh;
				background: none;
				a{
					cursor: pointer;
					background: none;
					padding: 0;
					margin: 0 5px;
					position: relative;

					&:hover:after{
						z-index: 2;
						position: absolute;
						top: calc(-50% + 3px);
						border-radius: 3px;
						left: 0;
						height: 100%;
						right: 0;
						bottom: 0;
						box-shadow: 1px 0 20px rgba(0,0,0, .25);
						border: 1px solid white;
					}

					svg{
						border-radius: 3px;
						width: 35px;
						overflow: hidden;
					}
				}
			}
			&.active{
				a{
					background: $secondary-color;
				}
			}

			a:hover,
			a:focus,
			a:active{
				text-decoration: none;
				box-shadow: none!important;
				outline: none;
				background: rgba(255, 255, 255, 0.6);
			}
		}
	}
}
</style>
<script>
	export default{
		props: {
			all:{ default:true },
		},
		data: function(){
			return {
				open:false,
			}
		},
		computed:{
			current(){
				return this.$route.name;
			}
		},
		methods:{
			close(){
				this.open = false;
			},
			beforeEnter: function (el) {
				el.style.opacity = 0;
			},
			enter: function (el) {
				var delay = el.dataset.index * 150
				setTimeout(function () {
					el.style.opacity = 1;
				}, delay)
			},
			leave: function (el) {
				var delay = el.dataset.index * 150
				setTimeout(function () {
					el.style.opacity = 0;
				}, delay)
			}
		}
	}
</script>
