import Vue from 'vue'
import Vuex from 'vuex'
// import SessionStorage from './utils/SessionStorage.js';

Vue.use(Vuex)

// import Panier from "./services/Panier";
// import LocalStorage from './utils/LocalStorage';
// import {join} from "./utils/utils";


// TRAD
import {i18n, messages} from './i18n.js';
// import {i18n, messages} from "./plugin/i18n";

// COOKIE
var vueCookie = require('vue-cookie');
let domaine = window.location.hostname;

import router from './router';
// import Produit from "./services/Produit";

// let config = {
// 	panier:true,// Affichage du pannier, passer commande
// 	language:false,// Changer de langue
// 	currency:false,// Changer de devise
// 	abonnement:false,// Abonnement dans le panier
// 	country:false,// Changer de country
// }


const state = {
	config:{
		language:null
	},
	
	languages:['en', 'fr', 'es'],

	notifications:[],

	scroll:{
		y: 0,
		down: null,
		height: 0,
	},

	modalClickPos:{
		x:0,
		y:0
	},
	modalPos:{
		x:0,
		y:0
	},

	loading:[],
	nav_img:null,
	nav_color:null,
	nav_logo:true,
	nav_fixed:false,
	nav_complet:true,
};


const getters = {
	languages: state => {
		return state.languages;
	},

	language: state => {
		return state.config.language;
	},

	navimg: state => {
		return state.nav_img;
	},
	navcolor: state => {
		return state.nav_color;
	},
	navlogo: state => {
		return state.nav_logo;
	},
	navfixed: state => {
		return state.nav_fixed;
	},
	navcomplet: state => {
		return state.nav_complet;
	},
	scroll: state => {
		return state.scroll;
	},

	is_loading: state => {
		return state.loading.length>0;
	},
	loading: state => {
		return state.loading;
	},

	notifications: state => {
		return state.notifications;
	},
};

const actions = {

};


let notifDefault = function(){
	return {
		id:'notif-'+new Date().getTime()+'-'+Math.floor(Math.random()*100),
		message:null,
		detail:null,
		progress:null,
		step:null,
		steps:null,
		callback:null,//['Btn label', function()]
		items:null,//{id:{label:'String', },{label:'String2'}}
		timeout:10,//null ou int
		read:0,
		// documents:null,
	};
};

const mutations = {

	setLanguage: function(state, language){

		let notif = vueCookie.get('config.language') && vueCookie.get('config.language')!== language;
		// console.log("setLanguage", state.config.language , ">", language, notif);

		state.config.language = language;
		vueCookie.set('config.language', language, {expires:1, domain: domaine});

		// console.log(Object.keys(messages));
		if (Object.keys(messages).indexOf(language) !== -1) {
			// LocalStorage.set('language', language);
			// Vue.config.lang = langue;
			i18n.locale = language;

			let current = router.currentRoute;
			// console.log('current.config.lang', current.params, current.params.lang);//, current.params.lang);
			if(current.params.lang){
				// console.log(current);
				let newRoute = Object.assign({}, current, {params:{lang:language}});
				// console.log(newRoute);
				//
				// // let newRoute = .fullPath.replace(this.$router.currentRoute.params.lang, langue);
				// // // console.log("push router", newRoute);
				// //
				// // this.$router.push({ name: 'foo', params: {title: 'test title' }})
				router.push(newRoute);
			}

			if(notif){
				mutations.notifPush(state, {
					id:'set-info-language',
					message: `langue.change.success.${language}`
					// message: this.$t("langue.change.success", {langue: this.$t("langue." + language)})
				});
			}
		} else {
			if(notif) {
				mutations.notifPush(state, {
					id: 'set-info-language',
					message: "langue.change.error"
					// message: this.$t("langue.change.error")
				});
			}
		}
	},

	setCookie: function(state, cookie){
		// console.log("setCookie", cookie);
		vueCookie.set('cookie', cookie, {expires:1, domain: domaine});
	},
	//
	// setNavColor: function(state, color){
	// 	state.nav_color = color;
	// },
	setNavImg: function(state, img){
		state.nav_img = img;
	},
	// setNavLogo: function(state, logo){
	// 	state.nav_logo = logo;
	// },
	// setNavFixed: function(state, fixed){
	// 	state.nav_fixed = fixed;
	// },
	// setNavComplet: function(state, complet){
	// 	state.nav_complet = complet;
	// },


	setScroll: function(state, scroll){
		state.scroll = scroll;
	},
	// modalSetPos: function(state, pos){
	// 	state.modalPos = pos;
	// },
	setModalClickPos: function(state, event){
		// console.log(event.clientX, event.clientY, event);
		state.modalClickPos = {
			x: event.clientX,
			y: event.clientY,
		}
	},


	loading: function(state, {url, status}){
		if(status){
			state.loading.push(url);
		}else{
			var index = state.loading.indexOf(url);
			if(index>-1) {
				state.loading.splice(index, 1);
			}
		}
	},
	track: function(state, payload){
		console.log(state, payload);
		// state.tracking.push({
		// 	ref:payload.ref,
		// 	date:new Date().toISOString(),
		// 	arg:payload.arg,
		// 	url:window.location.href
		// });
		// if(state.tracking_timeout){
		// 	clearTimeout(state.tracking_timeout);
		// }
		// state.tracking_timeout = setTimeout(function(){
		// 	Xhr('POST', 'track', state.tracking);
		// 	state.tracking = [];
		// }.bind(this), 30*1000);
		//
		// if(state.tracking.length > 100){
		// 	Xhr('POST', 'track', state.tracking);
		// 	state.tracking = [];
		// }
	},

	// notifPush:function(state, data){
	// 	let id = data.id ? data.id : Math.floor(Math.random()*1000);
	// 	let d = Object.assign({}, {id:id, read:0, progress:null, timeout:20}, data);
	// 	console.log('notifPush', d);
	// 	state.notifications.push(d);
	// },
	notifPush(state, data){
		let notif = Object.assign(
			{},
			notifDefault(),
			data
		);
		// console.log("notifPush", notif);
		Vue.set(state.notifications, notif.id, notif);
		return notif.id;
	},
	notifSet(state, datas){
		let field = datas.field;
		let id = datas.id;
		let data = datas.data;
		let notif = null;
		if(!state.notifications[id]){
			notif = notifDefault();
			notif.id = id;
		}else{
			notif = state.notifications[id];
		}

		notif[field] = data;
		notif.read = 0;
		Vue.set(state.notifications, notif.id, notif);
	},
	notifRead(state, id){
		let notif = state.notifications[id];
		notif.read = 1;
		Vue.set(state.notifications, id, notif);
	},
	notifDeleteAll(state){
		state.notifications = {};
	},
	notifReadAll(state){
		for(let i=0; i<state.notifications.length; i++) {
			state.notifications[i].read = 1;
		}
	},
}

const store = new Vuex.Store({
	state,
	getters,
	actions,
	mutations,
});

export default store;
