// VUE
import Vue from 'vue';

// ROUTER
import VueRouter from 'vue-router';
import router from './router';
Vue.use(VueRouter);

// META
import Meta from 'vue-meta';
Vue.use(Meta);

// TRAD
import {i18n} from './i18n.js';

// STORE
import store from './store.js';

// DIRECTIVES
import { directive as onClickOutside } from 'vue-on-click-outside'
Vue.directive('on-click-outside', onClickOutside);

// COMPONENTS
import Logo from "./components/logo";
import Btn from "./components/btn";
import Imgbg from "./components/imgbg";
const Sdata = () => import(/*webpackChunkName:"sdata"*/ './components/sdata.vue');
Vue.component('Logo', Logo);
Vue.component('Sdata', Sdata);
Vue.component('Btn', Btn);
Vue.component('Imgbg', Imgbg);

const RouterLink18n = () => import(/*webpackChunkName:"links"*/ './components/router-link18n.vue');
Vue.component('RouterLink18n', RouterLink18n);

// MIXIN
Vue.mixin({
	data: function(){
		return {
			social:{
				root:'https://www.fashionconsulting-agency.com',
				tel:'+33 6 08 73 54 19',
				telI18n:'+33608735419',
				mail:'nicole.marciano@fashionconsulting-agency.com',
				instagram:'https://www.instagram.com/nicole_marciano_',
				linkedin:'https://fr.linkedin.com/in/nicole-marciano-822748198',
			}
		}
	},
	computed: {
		env: function () {
			return process.env;
		},
		langue:function(){
			return this.$i18n.locale;
		},
	},
	methods: {
		gtag() {
			// action, category, label
			// console.log("gtag", action, category, label);
			// this.$gtag.event(action, {
			// 	event_category: category ? category : null,
			// 	event_label: label ? label : null,
			// 	// value: value
			// });
		},

		playSound(ref, volume=1, loop=false) {
			let file = null;
			switch(ref){
				case 'logo': file = '1.mp3'; break;
				case 'link': file = '2.mp3'; break;
				case 'mail': file = '3.mp3'; break;
				case 'ambiance': file = '4.mp3'; break;
			}
			if(file) {
				var audio = new Audio('/audio/'+file);
				audio.volume = volume;
				audio.loop = loop;
				audio.play();
			}
		}
	}
});

// // GOOGLE ANALYTICS - GTAG
// import VueGtag from "vue-gtag";
// if(process.env.NODE_ENV==='production'){
// 	Vue.use(VueGtag, {
// 		// config: { id: "G-WCR6YZ2PP3" }
// 		config: { id: "UA-188580884-1" }
// 	}, router);
// }

import App from './app.vue';
new Vue({
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount('#app');
