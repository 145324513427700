<template>
	<div class="anim-container">
		<div class="anim">
			<transition name="scale" mode="out-in">
				<div :key="texte" v-html="texte"></div>
			</transition>
		</div>

		<transition name="fade" mode="out-in">
			<div class="skip" v-if="skipable">
				<router-link18n
					:to="{name:'accueil'}"
					class="text-primary btn-link"
					@mouseover.native="playSound('link')">
					<a>{{ $t('anim.skip')}}</a>
				</router-link18n>
			</div>
		</transition>
	</div>
</template>
<style lang="scss">
	@import '../../src/assets/less/variables.scss';

	.anim-container{
		.anim{
			text-align: center;
			overflow: hidden;
			font-size: 2em;
			@include smartphones(){
				font-size: 1.4em;
			}
			z-index: 0;
			padding: 10vh 5vw 2vh;
			@include smartphones(){
				padding-top: 5vh;
			}
			color: black;

			span{
				animation: animspan .5s ease-in-out backwards var(--d, .25s);
				padding: 2px 5px;
				margin: -2px -5px;
				background:linear-gradient( to bottom, $secondary-color 0%, $secondary-color 100%);
				background-position: 0 100%;
				background-repeat: repeat-y;
			}
		}
		@keyframes animspan {
			from {
				background-size: 0px 1px;
			}
			to {
				background-size: 100% 1px;
			}
		}

		.skip{
			position: absolute;
			bottom:0;
			left:0;
			right: 0;
			text-align: center;
			margin-bottom: 15vh;
			@include smartphones(){
				margin-bottom: 10vh;
			}
		}

		.scale-enter-active {
			transition: all .3s ease-in-out;
		}
		.scale-leave-active {
			transition: all .3s ease-in-out;
		}
		.scale-enter{
			transform: scale(1.5);
			opacity: 0;
		}
		.scale-leave-to{
			transform: scale(0.5);
			opacity: 0;
		}

		.fade-enter-active,
		.fade-leave-active {
			transition: opacity 1s;
		}
		.fade-enter,
		.fade-leave-to{
			opacity: 0;
		}
	}
</style>
<script>
	export default{
		data: function() {
			return {
				timeout:null,
				skipable:false,
				index:0,
				texte:null,
				textes: {
					fr:[
						{t:"Vous êtes passionnés<br>par <span>la mode</span>", d:4},
						{t:"L'univers <span>du luxe</span>", d:2.5},
						{t:"La grande distribution", d:2.5},
						{t:"Vous aimez les <span>challenges</span>", d:2.5},
						{t:"Alors rejoignez notre équipe !", d:2.5},
						{t:"Le cabinet de recrutement spécialisé dans les métiers de la <span style='--d:1.5s'>mode</span>, du <span style='--d:1.8s'>luxe</span> et de la <span style='--d:2.5s'>joaillerie</span>.", d:6},
					],
					en:[
						{t:"You are passionate about <span>fashion</span>", d:4},
						{t:"The <span>luxury</span> industry", d:2.5},
						{t:"Large-scale distribution", d:2.5},
						{t:"You adore <span>challenges</span>", d:2.5},
						{t:"You are highly <span>motivated</span> and prepared to put a lot into a company", d:4.5},
						{t:"Well then, join our group !", d:2.5},
						{t:"The recruitment agency specialized in jobs in the <span style='--d:1.5s'>fashion</span>, <span style='--d:1.8s'>luxury</span> and <span style='--d:2.5s'>jewelry</span> industries.", d:6}
					]
				}
			}
		},
		created(){
			this.index = 0;
			this.timeout = setTimeout(this.incremente, 2000);
			setTimeout(function(){ this.skipable = true; }.bind(this), 500);
		},
		destroyed () {
			if(this.timeout) clearTimeout(this.timeout);
		},
		methods:{
			incremente(sens=1, timeout=true){
				let items = this.textes[this.langue];
				let item = items[this.index];

				if(item){
					this.texte = item.t;
					let delay = item.d * 1000;
					if(timeout) this.timeout = setTimeout(this.incremente, delay);
				}else{
					this.skipable = false;
					this.$router.push({name:'accueil'});
					return;
				}
				this.index = this.index + parseInt(sens);
			}
		}
	}
</script>
