<template>
	<div>
		<section class="text-justify">
			<h1>{{ $t('clients.h1') }}</h1>
		</section>

		<imgbg :full="false" height="40vh" :srcs="['clients-2.jpg','clients-3.jpg','clients-12.jpg','clients-14.jpg','clients-17.jpg']" />

		<section class="text-justify">
			<h2 class="text-primary">{{ $t('clients.s1-h2') }}</h2>
			<div class="mt-2">{{ $t('clients.s1-div') }}</div>
		</section>

		<section class="text-justify">
			<h2 class="text-primary" v-html="$t('clients.s2-h2')"></h2>
			<div class="mt-2">{{ $t('clients.s2-div') }}</div>
		</section>

		<section class="text-justify">
			<h2 class="text-primary">{{ $t('clients.s3-h2') }}</h2>
			<div class="mt-2">{{ $t('clients.s3-div') }}</div>
		</section>

		<section class="text-center">
			<a class="btn btn-primary btn-lg ellipsis m-1" :href="'tel:'+social.telI18n" @click="gtag('click', 'tel', 'contact')">{{ social.tel }}</a>

			<div class="text-muted small mt-2">
				<span class="d-block d-sm-inline-block">{{ $t('clients.ou-email') }} :</span>
				&nbsp;
				<a :href="'mailto:'+social.mail" @click="gtag('click', 'mail', 'contact')" class="text-muted btn-link">{{ social.mail }}</a>
			</div>
		</section>
	</div>
</template>
<script>
	export default{
		metaInfo () {
			return {
				title: this.$t("clients.meta.title"),
				meta: [
					{name: 'description', content: this.$t("clients.meta.description"), id: 'desc'},
				]
			}
		}
	}
</script>
