import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

import localeEn from './locales/en.json';
import localeFr from './locales/fr.json';

const messages = {
	'fr': localeFr,
	'en': localeEn
};

const i18n = new VueI18n({
	locale: navigator.language.split('-')[0] || 'fr', // set locale
	fallbackLocale: 'fr', // set fallback locale
	messages, // set locale messages
	silentTranslationWarn:true,
	silentFallbackWarn:true,
});

export { i18n, messages }
