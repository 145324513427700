<template>
	<div v-if="multi">
		<transition name="fade" mode="in-out">
			<div class="img img-bg bg-column">
				<div class="bg-col col1">
					<img v-for="i in 9" :key="i" class="multi" :src="`/img/clients-${(i)}.jpg`" alt="Fashion Consulting - Nicole Marciano" />
				</div>
				<div class="bg-col col2">
					<img v-for="i in 10" :key="i" class="multi" :src="`/img/clients-${(i+9)}.jpg`" alt="Fashion Consulting - Nicole Marciano" />
				</div>
			</div>
		</transition>
	</div>

	<div v-else-if="full && image">
		<div v-if="srcs.length>1" class="img-btns btns-bg">
			<span v-for="i in srcs.length" :key="i" :style="{width:(100/(srcs.length+2))+'%'}" :class="index===i-1 ? 'active':''" @click="setIndex(i-1)"></span>
		</div>

		<transition name="fade" mode="in-out">
			<div class="img img-bg" :key="image" :style="{backgroundImage:'url('+image+')'}"></div>
		</transition>
	</div>

	<section v-else :class="hiddenlg" style="position:relative;" :style="{minHeight:height}">
		<div v-if="srcs.length>1" class="img-btns">
			<span v-for="i in srcs.length" :key="i" :style="{width:(100/(srcs.length+2))+'%'}" :class="index===i-1 ? 'active':''" @click="setIndex(i-1)"></span>
		</div>

		<transition name="fade" mode="in-out">
			<div class="img img-sm" :key="image" style="position: absolute;top:0;left: 0" :style="{minHeight:height, backgroundImage:'url('+image+')'}"></div>
		</transition>
	</section>
</template>
<style lang="scss" scoped>
@import '../assets/less/variables.scss';
	.bg-column{
		padding-right: 50%;
		@include notebook(){
			padding-right: 70%;
		}
		@include tablettes(){
			display: none;
		}
		@include smartphones() {
			display: none;
		}
		text-align: center;
		.bg-col{
			display: inline-block;
			width: 300px;
			padding: 0 10px;
			max-width: 50%;
			vertical-align: top;
			img{
				border-radius: 3px;
				box-shadow: 0 0 20px rgba(0,0,0,.1);
				width: 100%;
				&+img{
					margin-top: 20px;
				}
			}
		}
		.col1{
			transform: translateY(0);
			animation: defilement1 25s both linear 0.5s;
		}
		.col2{
			transform: translateY(calc(-100% + 100vh));
			animation: defilement2 25s both linear 0.5s;
		}
	}

	@keyframes defilement1 {
		0% {
			transform: translateY(0);
		}
		100% {
			transform: translateY(calc(-100% + 100vh));
		}
	}
	@keyframes defilement2 {
		0% {
			transform: translateY(calc(-100% + 100vh));
		}
		100% {
			transform: translateY(0%);
		}
	}

	.img-btns{
		z-index: 1;
		position: absolute;
		bottom: 0;
		left: 15px;
		right: 15px;

		@include smartphones(){
			text-align: center;
		}
		span{
			cursor: pointer;
			//padding: 10px 0 5px;
			opacity: 0.5;
			max-width: 50px;
			display: inline-block;
			position: relative;
			text-shadow: 0 0 10px rgba(0,0,0,.5);
			padding: 10px 2px 10px;
			&:after{
				content: '';
				position: absolute;
				top:10px;
				left:2px;
				right: 2px;
				bottom: 0;
				height: 2px;
				background: white;
			}
			&.active{
				&:after{
					background: $primary-color;
				}
			}
		}
	}

	.img{
		background-position: center;
		background-size: cover;
		background-color: $secondary-color;
	}
	.img-sm{
		max-width: 100%;
		border-radius: 3px;
		width: 100%;
	}
	.img-bg{
		position: fixed;
		z-index: -1;
		top:0;
		left: 0;
		right: 0;
		bottom:0;
		@include smartphones(){
			display: none;
		}
	}
	.btns-bg{
		@include smartphones(){
			display: none;
		}
	}

	.fade-enter-active{
		transition: opacity .5s ease-in-out;
	}
	.fade-leave-active{
		display: none;
	}
	.fade-enter, .fade-leave-to{
		opacity: 0;
	}
</style>
<script>

export default{
	name:'imgbg',
	props:{
		full:{default:true},
		srcs:{default:function(){ return []; }},
		hiddenlg:{default:'d-block d-lg-none d-print-none'},
		height:{default:'20vh'},
		loop:{default:false},
	},
	data: function() {
		return {
			image: null,
			interval: null,
			index: 0,
		}
	},
	computed:{
		multi(){
			return this.srcs === 'multi';
		},
	},
	created() {
		this.carousel_plus();
	},
	destroyed () {
		this.stopCarousel();
	},
	watch:{
		srcs(){
			if(this.interval) clearTimeout(this.interval);
			this.index = 0;
			this.carousel_plus();
		}
	},
	methods:{
		stopCarousel(){
			if(this.interval) clearTimeout(this.interval);
		},
		setIndex(i){
			this.stopCarousel();
			this.index = i;
			this.carousel_plus();
		},
		carousel_plus(){
			if(this.srcs.length===0) return;

			this.load('/img/'+ this.srcs[this.index]);

			this.interval = setTimeout(function(){
				this.index = this.index+1;
				if(this.index >= this.srcs.length){
					this.index = this.srcs.length -1;
					return;
				}
				this.carousel_plus();
			}.bind(this), 2000);
		},
		load(img_src){
			if(!this.full){
				img_src = img_src.replace('.jpg','-xs.jpg');
			}

			let img = new Image();
			img.onload = function() {
				this.image = img_src;
			}.bind(this);
			img.onerror = function() {
				console.log("ON ERROR", img_src);
			};
			img.src = img_src;
		}
	}
}
</script>
