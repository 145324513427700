<template>
	<div>
		<imgbg :full="false" height="60vh" :srcs="['mentions.jpg']" />

		<section>
			<h2 class="text-primary">{{ $t('mentions.informations-generales') }}</h2>
			<div>Fashion Consulting</div>
			<div>Tel : <a :href="'tel:'+social.telI18n" @click="gtag('click', 'tel', 'contact')">{{ social.tel }}</a></div>
			<div class="ellipsis">Mail : <a :href="'mailto:'+social.mail" @click="gtag('click', 'mail', 'contact')">{{ social.mail }}</a></div>
			<div class="ellipsis">Instagram : <a :href="social.instagram" @click="gtag('click', 'instagram', 'contact')" target="_blank" rel="noopener">{{social.instagram }}</a></div>
			<div class="ellipsis">Linkedin : <a :href="social.linkedin" @click="gtag('click', 'linkedin', 'contact')" target="_blank" rel="noopener">{{social.linkedin }}</a></div>
			<div class="ellipsis">Url : <router-link18n :to="{name:'accueil'}" @click.native="gtag('click', 'logo', 'nav')">www.fashionconsulting-agency.com</router-link18n></div>
			<div>{{ $t('mentions.editorial') }} : Nicole Marciano</div>
		</section>

		<section>
			<h2 class="text-primary">{{ $t('mentions.libertes') }}</h2>
			<div class="text-justify mt-2">{{ $t('mentions.libertes-div') }}</div>
		</section>

		<section>
			<h2 class="text-primary">{{ $t('mentions.auteur') }}</h2>
			<div class="text-justify mt-2">{{ $t('mentions.auteur-div-1') }}</div>
			<div class="text-justify mt-2">{{ $t('mentions.auteur-div-2') }}</div>
		</section>

		<section>
			<h2 class="text-primary">{{ $t('mentions.contenu') }}</h2>
			<div class="text-justify mt-2">{{ $t('mentions.contenu-div-1') }}</div>
			<div class="text-justify mt-2">{{ $t('mentions.contenu-div-2') }}</div>
			<div class="text-justify mt-2">{{ $t('mentions.contenu-div-3') }}</div>
			<div class="text-justify mt-2">{{ $t('mentions.contenu-div-4') }}</div>
		</section>

	</div>
</template>
<script>
	export default{
		metaInfo () {
			return {
				title: this.$t("mentions.meta.title"),
				meta: [
					{name: 'description', content: this.$t("mentions.meta.description"), id: 'desc'},
				]
			}
		}
	}
</script>
