import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import store from './store';

import Vide from './views/vide.vue'
import Accueil from './views/accueil.vue'
import Clients from './views/clients.vue'
import Candidats from './views/candidats.vue'
import Contact from './views/contact.vue'
import Mentions from './views/mentions.vue'
import Anim from './views/anim.vue'

const routes = [

	{ path:'/:lang',
		component: Vide,
		beforeEnter(to, from, next){
			const paramsLanguage = to.params.lang;
			if(!store.getters.languages.includes(paramsLanguage)){
				return next('fr');
			}
			store.commit('setLanguage', paramsLanguage);

			return next();
		},
		children:[
			{ path: '', component: Anim, name:'anim', meta:{img:['intro.jpg']}},
			{ path: 'accueil', component: Accueil, name:'accueil', meta:{img:['accueil.jpg'], color:'white'}},
			{ path: 'clients', component: Clients, name:'clients', props:true, meta:{img:'multi', color:'white'}},
			{ path: 'candidats', component: Candidats, name:'candidats', props:true, meta:{img:['candidats-1.jpg','candidats-2.jpg','candidats-3.jpg','candidats-4.jpg','candidats-5.jpg','candidats-8.jpg','candidats-6.jpg','candidats-7.jpg'], color:'white'}},
			{ path: 'contact', component: Contact, name:'contact', props:true, meta:{img:['contact-1.jpg','contact-2.jpg'], color:'white'}},
			{ path: 'mentions', component: Mentions, name:'mentions', props:true, meta:{img:['mentions.jpg'], color:'white'}},
		]},
	{ path: '/', redirect: '/fr'},
];

console.log("ENV", process.env);
const router = new VueRouter({
	routes,
	base: process.env.VUE_APP_BASE_URL,
	linkActiveClass: "active",
	linkExactActiveClass: "active",
	mode: process.env.VUE_APP_ROUTER_MODE,
	scrollBehavior () {
		document.getElementsByClassName('main-container')[0].scrollTo(0,0);
		return { x: 0, y: 0 }
	}
});

router.beforeEach((to, from, next) => {
	let metas = Object.assign({}, to.meta);

	if(to.matched.length>0){
		metas = Object.assign({}, ...to.matched.map(function (value) {
			if(value && value.meta){
				return value.meta;
			}
			return value;
		}));
	}

	store.commit('setNavImg', metas.img ? metas.img : null);

	next();
});
router.beforeResolve((to, from, next) => {
	// Start route loading if this isn't an initial page
	if(to.name) {
		store.commit('loading', true);
	}
	next();
});

router.afterEach((to) => {
	// Vertical Nav
	let vars = Object.assign({}, to.meta);
	if(to.matched.length>0){
		vars = Object.assign({}, ...to.matched.map(function (value) {
			if(value && value.meta){
				return value.meta;
			}
			return value;
		}));
	}

	// Title
	switch(typeof vars.title){
		case 'function':
			document.title = vars.title(to);
			break;
		case 'string':
			document.title = vars.title;
			break;
		default:
			document.title = 'Fashion Consulting';
	}

	// Complete route loading.
	store.commit('loading', false);

	if (to.hash && document.querySelector(to.hash)) {
		return { selector: to.hash };
	}
});

export default router;
