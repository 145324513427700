<template>
	<div
		:class="'btn btn-'+color+(loading?' disabled':'')+' position-relative'"
		tabindex="0"
		@keypress.enter="onClick"
		@click="onClick"
		:disabled="loading">
		<slot></slot>
		<i class="loading fas fa-circle-notch fa-spin" v-if="loading"></i>
	</div>
</template>
<style scoped lang="scss">
	.loading{
		position:absolute;
		top: 0;
		bottom: 0;
		left:0;
		width: 48px;
		height: 48px;
		text-align: center;
		line-height: 48px;
	}
</style>
<script>
export default {
	name: "BtnLoading",
	props: {
		fa: {default: null},
		color: {default: 'light'},
		responsive: {default: false},
		label: {default: null},
		handle: {default:null},
		handledata: {default:function(){ return []}},
		finish: {default:1},
		confirm:{default:null}
	},
	data() {
		return {
			// disabled:false,
			loading:false,
			finished:false,
			event:false,
		}
	},
	methods:{
		onClick(event){
			// this.$emit('click');

			// this.event = event;
			if(this.handle){
				console.log("handle");
				if(this.loading) return;
				this.loading = true;
				console.log("go !", event.target);

				new Promise(resolve => {
					resolve( this.handle(...this.handledata) );
				})
					.then(function(response){

						event.target.blur();
						// event.blur();

						console.log("loader then data", response);
						this.$emit('callbackdata', response);
						// this.status = true // or success
						this.loading = false;

						// Afficher le btn finish
						// if(this.finish){
						// 	this.finished = true;
						// 	setTimeout(() => { this.finished = false }, 2000);
						// }
					}.bind(this))
					.catch(function(error){
						console.error(error)
						this.loading = false
						// this.status = false //or error
					}.bind(this))
			}

		},
	}
}
</script>
