<template>
	<div>
		<section class="text-primary text-justify">
			<h1>Fashion Consulting</h1>
			<h2>{{ $t('contact.agence') }}</h2>
		</section>

		<section id="contact">
			<div class="ellipsis">
				Tel : <a :href="'tel:'+social.telI18n" class="btn-link" @click="gtag('click', 'tel', 'contact')">{{ social.tel }}</a>
			</div>
			<div class="ellipsis">
				Mail : <a :href="'mailto:'+social.mail" class="btn-link" @click="gtag('click', 'mail', 'contact')">{{ social.mail }}</a>
			</div>
			<div>
				{{ $t('contact.retrouvez-nous-sur') }}
				<a :href="social.instagram" class="btn-link" @click="gtag('click', 'instagram', 'contact')" target="_blank" rel="noopener">Instagram</a>
				{{ $t('contact.et') }}
				<a :href="social.linkedin" class="btn-link" @click="gtag('click', 'linkedin', 'contact')" target="_blank" rel="noopener">Linkedin</a>.
			</div>
		</section>

		<section>
			<imgbg :full="false" hiddenlg="d-block d-lg-none d-print-none" height="70vh" :srcs="['contact-1.jpg','contact-3.jpg','contact-4.jpg','contact-2.jpg']" />
			<imgbg :full="false" hiddenlg="d-none d-lg-block d-print-none" height="70vh" :srcs="['contact-3.jpg','contact-4.jpg']" />
		</section>
	</div>
</template>
<script>
	export default{
		metaInfo () {
			return {
				title: this.$t("contact.meta.title"),
				meta: [
					{name: 'description', content: this.$t("contact.meta.description"), id: 'desc'},
				]
			}
		}
	}
</script>
