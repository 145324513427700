<template>
	<div id="app" class="overflow-hidden" @click="firstClick">

		<app-header :all="$router.currentRoute.name!=='anim'" />

		<div class="main-container">

			<router-link18n
				:to="{name:'accueil'}"
				class="logo-sm"
				:class="{intro:$router.currentRoute.name==='anim'}"
				@click.native="gtag('click', 'logo', 'nav')"
				aria-label="Logo - Fashion Consulting - Nicole Marciano">
				<logo />
			</router-link18n>

			<transition :name="transitionName" mode="out-in">
				<main class="flex-grow-1" :key="$router.currentRoute.fullPath">
					<router-view/>
				</main>
			</transition>

			<app-footer v-if="$router.currentRoute.name!=='anim'" />
		</div>

		<imgbg :srcs="$store.getters.navimg" />

		<sdata type="Organization" :data="sdataOrganization" />
	</div>
</template>

<style lang="scss">
	@import '../src/assets/less/app.scss';
</style>
<script>
	import AppHeader from "./views/header";
	import AppFooter from "./views/footer";

	export default{
		name: 'app',
		components:{
			AppHeader,
			AppFooter,
		},
		metaInfo () {
			return {
				// Children can override the title.
				title: this.$t("app.title"),
				// Result: My Page Title ← My Site
				// If a child changes the title to "My Other Page Title",
				// it will become: My Other Page Title ← My Site
				titleTemplate: '%s - Fashion Consulting',

				// Define meta tags here.
				meta: [
					{'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
					{name: 'viewport', content: 'width=device-width, initial-scale=1'},
					{name: 'description', content: this.$t("app.meta.description"), id: 'desc'},

					{ name: 'application-name', content: 'Fashion Consulting' },

					// OpenGraph data (Most widely used)
					{property: 'og:title', content: this.$t("app.meta.title")},
					{property: 'og:site_name', content: 'Fashion Consulting'},
					// The list of types is available here: http://ogp.me/#types
					{property: 'og:type', content: 'website'},
					// Should the the same as your canonical link, see below.
					{property: 'og:url', content: 'https://www.fashionconsulting-agency.com/'},
					{property: 'og:image', content: 'https://www.fashionconsulting-agency.com/img/fashion-consulting.jpg'},
					// Often the same as your meta description, but not always.
					{property: 'og:description', content: this.$t("app.meta.description")},

					// Google / Schema.org markup:
					{itemprop: 'name', content: 'Fashion Consulting'},
					{property: 'description', content: this.$t("app.meta.description")},

					{itemprop: 'image', content: 'https://www.fashionconsulting-agency.com/img/fashion-consulting.jpg'}
				]
			}
		},
		data: function(){
			return {
				transitionName:((window.innerWidth > 0) ? window.innerWidth : screen.width)>300 ? 'slide':null,
				click:null,
			}
		},
		methods:{
			firstClick(){
				if(!this.click){
					this.playSound('ambiance', .1, true);
					this.click = true;
				}
			}
		},
		computed:{
			sdataOrganization(){
				return {
					name:'Fashion Consulting Agency',
					url:this.social.root,
					logo:{
						'@type':'ImageObject',
						url:`${this.social.root}/img/logo.png`,
						width:500,
						height:200
					},
					description:'Fashion Consulting Agency est une agence parisienne, dirigée par Nicole Marciano, dédiée aux métiers de la mode, du luxe et de la joaillerie, sur toute la France et l\'étranger.',
					sameAs:[
						this.social.linkedin,
						this.social.instagram,
					],
					address:{
						'@type': "PostalAddress",
						// addressRegion: "NY",
						postalCode: "75016",
						addressCountry: "France",
						addressLocality: "Paris",
						// addressLocality: "Paris",
						streetAddress: "82 rue Michel Ange"
					},
					email:this.social.mail,
					telephone:this.social.telI18n,
					image:`${this.social.root}/img/fashion-consulting-agency.jpg`,
					areaServed: {
						'@type': 'GeoCircle',
						geoMidpoint: {
							'@type': 'GeoCoordinates',
							latitude: 48.8416544,
							longitude: 2.2588337
						},
						geoRadius: "30000"
					},
					founders:{
						'@type':'Person',
						name:'Nicole Marciano'
					},
					identifier:[
						{
							'@type':'PropertyValue',
							propertyID:'SIRET',
							value:'49201121800020',
						},
					]
				}
			}
		}
	}
</script>
